/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";

/*----------------------Cac control trong project su dung den trong form----------------*/
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  showError,
} from "../components/common";
import SimpleDialog from "../components/SimpleDialog";
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import MyLatLonEditor from "../components/MyLatLonEditor";
import { Shower } from "@mui/icons-material";
/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;

/*-----------------------Dinh nghia cac label tieng Anh, tieng Viet su dung trong form*/
const formTitle = englishUI ? "VOYAGE CODE" : "MÃ CHUYẾN ĐI";
const fieldLabels = englishUI
  ? {
      VoyageCode: "Voyage code:",
      ShipName: "Ship' name:",
      VoyageYear: "Year:",
      VoyageNum: "Number:",
    }
  : {
      VoyageCode: "Mã chuyến đi:",
      ShipName: "Tên tàu:",
      VoyageYear: "Năm:",
      VoyageNum: "Số chuyến:",
    };
const buttonLabels = englishUI
  ? {
      btnSave: "Save",
      btnClose: "Close",
    }
  : {
      btnSave: "Lưu",
      btnClose: "Thoát",
    };

function validate_Data(data) {
  var ret = myLib.validateEmpty(data, ["VoyageCode", "ShipName"]);
  if (Object.keys(ret).length == 0) {
    //ko co loi thi kiem tra cac dieu kien khac
  }

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version

  return ret;
}
////////////////////////--------------/////////////////////////////////////////
export default class VoyageEditDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      DanhMuc: {},
      after: {
        VoyageCode: "",
        ShipName: props.ShipData.ShipName,
        ShipID: props.ShipData.ShipID, //lay thong tin tu ship data

        ...(props.edittingRecord ? props.edittingRecord : {}),
      },
    };
  }

  componentDidMount() {
    this.loadDanhMuc();
  }
  //load du lieu khi form load
  loadDanhMuc() {
    //lay ma chuyen tu dong tiep theo
    if (this.props.edittingRecord == undefined) {
      this.setState({ saving: true });
      server
        .post("Data/DoRequest", {
          Function: "Proc_DM_Voyage_GetNewOne",
          ThamSo: {
            ShipID: this.state.after.ShipID,
          },
        })
        .then((response) => {
          //du lieu cua 1 do thi
          const list = response.DM_Voyage;

          this.state.after.VoyageCode = list[0].VoyageCode;
          this.state.after.VoyageYear = list[0].VoyageYear  ;
          this.state.after.VoyageNum = list[0].VoyageNum;
          this.setState({ saving: false });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ saving: false });
        });
    }
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    try {
      var check = validate_Data(this.state.after);
      //chuyen doi format
      var data = myLib.cloneObject(this.state.after);
      var values = data.ShipName.split("\n"); //string
      var list = [];
      values.map((str) => {
        var ps = str.split(";");
        var x = Number(ps[0]);
        var y = Number(ps[1]);
        list.push([x, y]);
      });
      data.ShipName = list; //thay the array number
      var action = this.props.edittingRecord ? "update" : "insert";
      if (!check.error) {
        this.setState({ validateStatus: check, saving: true, errorLog: "" });
        server
          .post("Data/DoRequest", {
            Function: "Proc_DM_Voyage_Update",
            ThamSo: data,
          })
          .then((response) => {
            this.setState({
              dataChanged: false,
              canSaved: false,
              saving: false,
              errorMessage: "",
              errorLog: "",
            });
            //thong bao cho parent biet co data duoc saved
            if (this.props.onSaved) {
              this.props.onSaved(action, data);
            }
            alert("Cập nhật thành công!");
            this.props.close(data);
          })
          .catch((error) => {
            handleServerError(error);
            this.setState({ saving: false });
          });
      } else {
        this.setState({ validateStatus: check });
        showError("Vui lòng kiểm tra các trường đánh dấu đỏ!");
      }
    } catch (ex) {
      showError(ex);
    }
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{formTitle}</DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.ShipName}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="ShipName"
                  fullWidth
                  readOnly
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 40,
                  }}
                  value={this.state.after.ShipName}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.VoyageCode}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="VoyageCode"
                  fullWidth
                  autoFocus
                  readOnly={this.props.edittingRecord !== undefined}
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.VoyageCode}
                  onChange={(event) => {
                    this.saveField(
                      "VoyageCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.VoyageCode !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.VoyageYear}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="VoyageYear"
                  fullWidth
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.VoyageYear}
                  onChange={(event) => {
                    this.saveField("VoyageYear", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageYear !== undefined}
                />
              </Grid>
              <Grid item xs={4}>
                <FormLabel>{fieldLabels.VoyageNum}</FormLabel>
              </Grid>
              <Grid item xs={20}>
                <OutlinedInput
                  id="VoyageNum"
                  fullWidth
                  inputProps={{
                    style: { textTransform: "uppercase" },

                    maxLength: 20,
                  }}
                  value={this.state.after.VoyageNum}
                  onChange={(event) => {
                    this.saveField("VoyageNum", event.target.value);
                  }}
                  error={this.state.validateStatus.VoyageNum !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            {buttonLabels.btnSave}
          </Button>

          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {buttonLabels.btnClose}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
