/**Nhap danh muc chu tau */
import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  OutlinedInput,
  InputBase,
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DatePicker } from "@mui/lab";
import SelectFilter from "react-select";
import { filterStyles } from "../components/common";
import QuyenPhanMemDialog from "./QuyenPhanMemDialog";

const myLib = require("../lib/MyLib");
const server = require("../lib/server");

const validator = require("../data/DangKy_Validator");

const styles = (theme) => ({
  red: {
    color: "white",
    backgroundColor: "#fc9003",
  },
  blue: {
    color: "white",
    backgroundColor: "#0384fc",
  },
  uppercaseText: {
    textTransform: "uppercase",
  },
  quantrong: {
    fontWeight: "bold !important",
  },
  colorBlue: {
    color: "blue",
  },
});

const fields = [
  "MaCang",
  "MaKV",
  "TenCang",
  "TenCang_TA",
  "MoTa",
  "MoTa_TA",
  "LoaiCang",
  "PhiNeo",
  "PhiDV",
  "Website",
  "STT",
  "HoatDong",
];

function isEmpty(v) {
  return v === undefined || v === null || v + "" === "";
}
function isZero(v) {
  return v === undefined || v === null || parseFloat(v) <= 0;
}
function validate_Empty(data, fields) {
  var ret = {};
  fields.map((name, index) => {
    if (isEmpty(data[name])) {
      ret[name] = true;
    }
  });
  return ret;
}
function validate_Key(data) {
  var ret = validate_Empty(data, ["CompanyCode", "ShipCode"]);

  ret.error = Object.keys(ret).length > 0;
  ret.GUID = new Date(); //danh dau version
  return ret;
}

class ChangePassDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validateStatus: { error: false }, //trang thai validate
      source_danhmuc: {},
      source_donvi: props.DanhMucDonVi,
      after: {
        CompanyCode: "",
        ShipCode: "",
        TotalDays: 10 * 365, // 10 nam
        JWT: "",
      },
    };
  }

  componentDidMount() {
    //this.loadDanhMuc();
  }
  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps, prevState) {
    return { open: nextProps.open };
  }
  saveField = (fieldName, value) => {
    var old_changed = this.state.dataChanged;
    var s = { after: { ...this.state.after } }; //new value
    s.after[fieldName] = value;
    s.dataChanged = true;
    this.setState(s);
  };
  saveData = (obj) => {
    var old_changed = this.state.dataChanged;
    var s = {};
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    s.after = { ...this.state.after, ...obj };
    this.setState(s);
  };
  doSave = () => {
    var check = validate_Key(this.state.after);
    if (!check.error) {
      this.state.after.JWT = "";
      this.setState({ validateStatus: check, saving: true, errorLog: "" });
      server
        .post("PhanQuyen/CreateUploadBearer", this.state.after)
        .then((response) => {
          var before = {};
          var after = {
            ...this.state.after,
            JWT: response.JWT,
          };
          Object.assign(before, after);
          this.setState({
            before: before,
            after: after,
            dataChanged: false,
            canSaved: false,
            saving: false,
            errorMessage: "",
            errorLog: "",
          });
        })
        .catch((error) => this.handleServerError(error));
    } else {
      this.setState({ validateStatus: check });
      toast.error("Vui lòng kiểm tra các trường đánh dấu đỏ!", {
        position: "bottom-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
      });
    }
  };
  handleServerError(error) {
    var message = "";
    var log = "";
    try {
      var obj = JSON.parse(error);
      message = obj.message;
      log = obj.traceLog;
      var log_detail = JSON.parse(log);

      if (log_detail && log_detail.Message) {
        message += "\n<br>" + log_detail.Message;
        if (log_detail.ExceptionMessage) {
          message = log_detail.ExceptionMessage + "\n" + message;
        }
      }
    } catch (e) {
      message = error + "";
    }
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      progress: undefined,
    });
  }
  render() {
    const { classes, theme } = this.props;
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => {}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tạo JWT key cho data-logger
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={6}>
                <FormLabel>Ký hiệu khách hàng:</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  autoFocus
                  
                  inputProps={{
                    className: classes.uppercaseText,
                    maxLength: 20,
                  }}
                  value={this.state.after.CompanyCode}
                  onChange={(event) => {
                    this.saveField(
                      "CompanyCode",
                      myLib.toUpperCase(event.target.value)
                    );
                  }}
                  error={this.state.validateStatus.CompanyCode !== undefined}
                />
              </Grid>

              <Grid item xs={6}>
                <FormLabel>Ký hiệu tàu(*):</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.ShipCode}
                  onChange={(event) => {
                    this.saveField("ShipCode", myLib.toUpperCase(event.target.value));
                  }}
                  error={this.state.validateStatus.ShipCode !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>Số ngày hết hạn:</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.TotalDays}
                  onChange={(event) => {
                    this.saveField("TotalDays", event.target.value);
                  }}
                  error={this.state.validateStatus.TotalDays !== undefined}
                />
              </Grid>
              <Grid item xs={6}>
                <FormLabel>JWT key:</FormLabel>
              </Grid>
              <Grid item xs={18}>
                <OutlinedInput
                  fullWidth
                  value={this.state.after.JWT}
                  multiline
                  onChange={(event) => {}}
                  error={this.state.validateStatus.JWT !== undefined}
                />
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              this.doSave();
            }}
            variant="contained"
          >
            Tạo key
          </Button>
          <Button
            onClick={() => {
              if (this.props.close) {
                this.props.close(false);
              }
            }}
            variant="contained"
          >
            {this.props.confirm ? "CANCEL" : "Thoát"}
          </Button>
        </DialogActions>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </Dialog>
    );
  }
}
export default withStyles(styles, { withTheme: true })(ChangePassDialog);
